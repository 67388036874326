.ec-navlistRole {
  .ec-navlistRole__navlist {
    background: #F8F8F8;
    border-radius: 4px;
    border: unset;

    @include media_desktop {
      display: grid;
      grid-template-columns: repeat(7,1fr);
    }
    @include media_middle {
      display: grid;
      grid-template-columns: repeat(4,1fr);
    }
    @include media_mobile {
      background: unset;
    }
    .ec-navlistRole__item {
      background-color: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 13px;
      border: unset;
      a {
        padding: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96%;
        height: 90%;
        &:hover {
          background:#E4F7E6;
          color: #3A9943;
        }
      }

      .ec-navlistRole__item-box {
        height: 90%;
        width: 96%;
        border-radius: 4px;
        color: #666666;
        &:hover {
          background: #E4F7E6;
          color: #3A9943;
        }
        @include media_mobile {
          background: unset;
        }
      }
      &.active {
        .ec-navlistRole__item-box {
          background: #E4F7E6;
          color: #3A9943;
          @include media_mobile {
            background: unset;
          }
        }

        a {
          background:#E4F7E6;
          color: #3A9943;
          @include media_mobile {
            background: unset;
          }
        }
      }
      @include media_mobile {
        border-bottom: 1px solid gray;
      }
    }
  }
}
