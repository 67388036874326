#page_sln_edit_card {
  .ec-pageHeader {
    h1 {
      margin: 0 0 8px;
      border-bottom: 1px dotted #ccc;
      border-top: 1px solid #ccc;
      padding: 8px 0 12px;
      font-size: 16px;
      font-weight: bold;
      @include media_desktop {
        border-top: none;
        border-bottom: 1px solid #ccc;
        font-size: 32px;
        font-weight: bold;
        margin: 10px 0 48px;
        padding: 8px 0 18px;
      }
    }
  }
  .ec-halfInput {
    #card_CardNo {
      @include media_mobile {
        width: 100%;
      }
    }
  }
  .ec-label {
    font-weight: normal;
  }
}
