.change_page {
  .ec-secHeading {
    margin-top: 56px;
  }
  .ec-layoutRole__contents {
    .ec-registerRole {
      padding: 0;
      .ec-off1Grid {
        .ec-off1Grid__cell {
          margin: 0;
          width: 100%;
        }
      }
      .ec-pageHeader h1 {
        padding: 0;
        color: #212121;
        margin: 25px 0;
        border: none;
        font-weight: 900;
        font-size: 21px;
        line-height: 28.96px;
      }

      .ec-borderedDefs {
        border: none;
        dt {
          padding-top: 0;
        }
        dd {
          width: 100%;
        }

        dl {
          display: flex;
          flex-direction: column;
          width: 100%;
          background: #ffffff;
          box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.04);
          margin-bottom: 12px;
          @include media_desktop {
            padding: 1.2em 2em 0.8em 2em;
          }
          padding: 1.2em 1em 0.8em 1em;
          input[type='text'], input[type='tel']
          , select, input[type='password'] {
            @include register_input;
            padding-left: 10px;
          }
        }
      }
    }
    .ec-label {
      margin-bottom: 0;
      font-weight: 400;
    }
    .ec-required {
      font-size: 1em;
      margin-left: 5px;
    }

    .ec-zipInputHelp {
      margin-bottom: 0;
      color: #008cf1;
      min-width: 110px;
    }

    .ec-zip-code-row {
      display: grid;
      grid-template-columns: 0.1fr 3fr;

      .ec-post-code-input {
        width: 100%;
        display: grid;
        grid-template-columns: 0.45fr 0.08fr 0.5fr 0.6fr;
        align-items: center;
        padding: 0 40% 0 0;

        .pre-post-code {
          &::after {
            @include middle-element(50%, 0);
            content: "";
            width: 30%;
            height: 4px;
            background-color: $black-color;
          }
        }

        button {
          line-height: 21.72px;
          font-weight: normal;
          color: #525263;
          font-size: 15px;
          background-color: #f0f3fd;
          border: 1px solid #d7dbe9;
          padding: 0;
        }
      }

      .ec-select {
        margin: 1.1em 0;
        position: relative;

        &::after {
          content: "";
          width: 10px;
          height: 10px;
          color: #333;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          right: 38%;
          /*Adjust for position however you want*/

          top: 40%;
          border-bottom: 3px solid #999;
          border-right: 3px solid #999;
          /*left line */

          position: absolute;
          pointer-events: none;
        }

        .p-region-id {
          width: 65%;
          background-color: #ffffff;
          border: 1px solid #dedede;
          border-radius: 4px;
          font-weight: normal;
          color: #525263;
          height: 40px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
      }

      .ec-input {
        .p-locality {
          margin: 1.1em 0 !important;
        }
        &.display-name {
          width: 50%;
          @include media_mobile {
            width: 100%;
          }
        }
      }
    }

    .input_tel {
      align-items: center;
      height: 40px;
      padding-right: 35%;
      gap: 0.5em;
    }

    .ec-birth {
      width: 100%;
      height: 40px;
      display: grid;
      grid-template-columns: 1fr 0.08fr 1fr 0.08fr 1fr;
      align-items: center;
      padding: 0 38% 0 0;

      select {
        height: 40px;
        margin: 0;
        padding: 0;
        background-color: #ffffff;
        border: 1px solid #dedede;
        border-radius: 4px;
      }
    }

    .ec-radio {
      #entry_sex {
        display: inline-flex;
        align-items: center;
        gap: 90px;
        height: 40px;
        @include checkedRadioAnimation;
      }
      #entry_UseFriendNoticeStatus {
        @include checkedRadioAnimation;
        display: inline-flex;
        gap: 10px;
      }
    }
    .form-inline {
      margin-bottom: 0;
      label {
        display: inline;
      }
      #entry_mailmaga_flg {
        @include checkedRadioAnimation;
        display: inline-flex;
        gap: 10px;
      }
    }

    .ec-checkbox {
      @include checkboxAnimation;
      label {
        display: flex;
        align-items: center;
      }
    }
  }

  .ec-registerRole__actions {
    display: flex;
    padding: 0 !important;
    justify-content: center;
    margin: 1em 0 2.3em 0;
    .ec-main-btn {
      height: 40px;
      text-align: center;
      min-width: 150px;
      margin-left: 20px;
    }
    .ec-cancel-btn {
      text-align: center;
      height: 40px;
      min-width: 150px;
      &:hover {
        color: $white-color;
        text-decoration: none;
      }
    }
  }
}
