.ec-PostRole {
  .ec-secHeading {
    margin-top: 50px;
  }
  .c-outsideBlock {
    .c-outsideBlock__contents {
      .search-post {
        margin-bottom: 40px;
        @include media_mobile {
          margin-bottom: 20px;
        }
        .select2-container {
          .select2-selection--single {
            height: 40px;
            border: 1px solid #dedede;
            .select2-selection__rendered {
              line-height: 38px;
            }
            .select2-selection__arrow {
              height: 38px;
            }
          }
        }
      }
    }
  }
  .c-post__items {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    .c-post__item {
      width: 30%;
      margin-right: 5%;
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 30px;
      }
      @include media_mobile {
        width: 100%;
        margin-right: unset;
      }
      .img-box {
        position: relative;
        overflow: hidden;
        &:after {
          content: "";
          display: block;
          padding-bottom: 55%;
        }
        .square {
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;
          .post-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.4s all ease-in-out;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      .c-post-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 23px;
        padding-top: 8px;
        a {
          color: $black-color;
        }
      }
      .c-post-category {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $black-color;
        padding-top: 4px;
        .c-post-category-lable {
          color: #A7A7A7;
        }
      }
      .c-post-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #747474;
        padding-top: 4px;
      }
      .c-post-user {
        margin-top: 12px;
        .c-post-user-avatar {
          img {
            width: 33.33px;
            height: 33.33px;
            border-radius: 50%;
          }
        }
        .c-post-user-info {
          padding-left: 10px;
          .c-post-user-name {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
          }
          .c-post-date {
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: #979797;
          }
        }
      }
    }
  }
  &.post-detail {
    .content {
      max-width: 900px;
      margin: 0 auto;
    }
    .c-post-user {
      margin-top: 12px;
      .c-post-user-avatar {
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
      .c-post-user-info {
        padding-left: 10px;
        .c-post-user-name {
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          color: #000000;
        }
        .c-post-date {
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          color: #A7A7A7;
        }
      }
      .post-title {
        font-size: 24px;
        margin-bottom: 10px;
        color: #000000;
      }
      .post-product {
        margin-bottom: 20px;
        .post-product-name {
          font-size: 14px;
          color: #a7a7a7;
        }
        a {
          color: unset;
        }
      }
      .post-content {
        position: relative;
        .edit-post {
          position: absolute;
          right: 15px;
          a {
            &:hover {
              text-decoration: none;
            }
            span {
              background: #295dd2;
              padding: 8px 20px;
              border-radius: 4px;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .c-post-main-img {
      margin-top: 30px;
      .post-image {
        width: auto;
        height: 50vh;
        object-fit: cover;
      }
    }
    .c-post-content {
      margin-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #d9d9d9;
      font-size: 14px;
      color: #747474 !important;
    }
  }
}
.post-new {
  .ec-layoutRole__contents {
    max-width: 100% !important;
    background-color: #e6e4e4;
    .container {
      margin-top: 60px;
      background: #FFFFFF;
      box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.04);
      padding: 0;
      .ec-PostRole.create-post-page {
        .ec-off1Grid {
          .ec-off1Grid__cell {
            padding: 0 32px 32px 32px;
            .ec-borderedDefs {
              .select2-hidden-accessible {
                width: 1px !important;
              }
              border-top: unset;
              dl {
                border-bottom: unset;
                display: block;
                padding: 0;
                dt {
                  margin: 20px 0 10px 0;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 22px;
                  color: #212121;
                  padding: 0;
                }
                dd {
                  width: 100%;
                  .ec-input {
                    &.select-product {
                      width: 50%;
                      line-height: 2;
                      select {
                        padding: 0;
                      }
                      .select2-container--default .select2-selection--single {
                        height: 40px;
                        border: 1px solid #DEDEDE;
                        border-radius: 4px;
                        .select2-selection__rendered {
                          line-height: 38px;
                          color: #3f3f3f;
                        }
                        .select2-selection__arrow {
                          height: 38px;
                        }
                      }
                    }
                  }
                  .post-input-image {
                    .image-display {
                      width: 192px;
                      height: auto;
                      border-radius: 4px;
                    }
                  }
                  &.post-description {
                    position: relative;
                    .post-description-note {
                      position: absolute;
                      right: 0;
                      top: -32px;
                    }
                  }
                }
              }
            }
            .ec-RegisterRole__actions {
              display: flex;
              padding: 0 !important;
              justify-content: center;
              margin: 1em 0 0 0;
              .ec-main-btn {
                height: 40px;
                padding: 0 63px;
              }
            }
          }
        }
      }
    }
  }
}
