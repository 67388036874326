.ec-login-ss {
  padding: 0 56px;

  p,
  label {
    font-size: 15px;
  }

  .ec-icon-ss {
    text-align: center;
    margin-bottom: 30px;
  }

  .title {
    font-size: 20px;
    color: $black-color;
  }

  .ec-main-btn {
    padding: 9px 105px;
  }

  .prepend-icon {
    @include middle-element(-50%, -50%);
    left: 15px;
  }

  .toggle-password {
    @include middle-element(-50%, -50%);
    cursor: pointer;
    right: 5px;
  }

  .ec-errorMessage {
    color: #de5d50;
    margin-bottom: 20px;
  }

  .form-check {
    padding: 0 1.6rem;
    @include checkboxAnimation;
    input[type="checkbox"] {
      margin: 0 0 0 -25px !important;
    }
  }
}

#page_mypage_login {
  background-color: #fff;

  .ec-login-ss {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .login-screen {

    .login-form {
      width: 682px;
      background-color: #F8F8F8;
    }
  }
}

@include media_small {
  #page_mypage_login {
    .login-screen-sp {
      font-weight: bold;
      height: 100%;
      padding: 2em 1em;
      margin: 0 auto;

      .form-login-sp {
        background-color: rgba(255, 255, 255, 0.823);
        margin: auto auto;
        padding: 1em;
      }
    }

    .ec-login-ss {
      padding: 0;
      .title, .font-20, .font-15 {
        font-size: 16px;
      }
      p, label {
        font-size: 14px;
      }
      .ec-icon-ss {
        max-width: 50%;
        margin: 0 auto 30px auto;
      }

      .ec-main-btn {
        width: 80%;
        margin: 0 auto;
        padding: 0.6em 0;
      }
    }
  }
}
