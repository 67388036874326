#page_mypage_delivery {
  .ec-addressList {
    .ec-addressList__item {
      display: flex;
      align-items: center;
      .ec-addressList__action {
        top: unset;
      }
    }
  }
}
