#createTopicModal {
  .ec-checkbox {
    @include checkboxAnimation;

    label {
      display: flex;
      align-items: center;
    }
  }

  .ec-main-btn {
    padding: 10px 23px;
  }
}

.ec-forumRole {
  .ec-secHeading {
    margin-top: 50px;
  }

  .actions {
    margin-bottom: 18px;
  }

  .search-forum {
    max-width: 500px;
    margin: 0 auto;
    @include media_mobile {
      width: 60%;
    }

    .reset-btn {
      @include middle-element(-50%, -50%);
      right: 0;
      padding: 0;

      img {
        width: 20px;
      }
    }
  }

  .list-forum {
    margin-top: 20px;

    .box {
      background: #F4F4F4;
      padding: 16px;
      margin-bottom: 30px;
      .img-box {
        &:after {
          padding-bottom: 55%;
        }
        &.w-30 {
          width: 30%;
        }
        .item-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.4s all ease-in-out;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .forum-right-content {
        width: 65%;
        margin-left: 20px;
      }
    }

    .title {
      a {
        font-size: 14px;
        font-weight: 700;
        color: $black-color;
        text-decoration: none;
      }
    }

    .content {
      margin-top: 7px;
      font-size: 14px;
      color: #414141;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
    }

    .created-time {
      margin-top: 10px;
      color: #B8B2B2;
    }
  }

  .list-topic {
    h2 {
      font-size: 30px;
      margin: 50px 0 25px 0;
      color: #212121;
    }

    .item {
      border-top: 1px solid #D9D9D9;
      padding: 16px 0 20px 0;

      .avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .title {
        font-size: 16px;
        font-weight: 700;
        color: $black-color;
        text-decoration: none;
      }

      .status {
        margin-top: 5px;
        font-size: 14px;
        color: #6A6A6A;
      }
      .create-topic-btn {
        text-decoration: none;
        border: none;
        padding: 0;
        color: #3a9943;
        background: unset;
      }
      .pin-topic-btn {
        text-decoration: none;
        border: none;
        padding: 0;
        color: #3a9943;
        background: unset;
      }
      .create-comment-btn {
        text-decoration: none;
        border: none;
        padding: 0;
        color: #3a9943;
        background: unset;
        cursor: pointer;
      }
      position: relative;
      .topic-action {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
      }
      .item-topic-content {
        display: -webkit-box;
      }
    }
  }

  .actions {
    h4 {
      font-size: 20px;
    }
  }

  .create-topic {
    cursor: pointer;
    background: #E2FFE4;
    border: 1px solid $green-color;
    color: $green-color;
    font-size: 14px;
    text-align: center;
    padding: 9px 18px;
  }
  .create-comment {
    cursor: pointer;
    background: #E2FFE4;
    border: 1px solid $green-color;
    color: $green-color;
    font-size: 14px;
    text-align: center;
    padding: 9px 18px;
  }
}
