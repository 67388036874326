.ec-newsRole {
  max-width: 750px;
  margin: 0 auto;
  padding: 30px 0 0;
  .ec-newsRole__news {
    padding: 0;

    .ec-newsRole__newsItem {
      display: none;
      margin-bottom: 12px;
      border: unset;
      border-bottom: 1px solid #dedede;
      padding: unset;
    }

    .ec-newsRole__newsHeading {
      align-items: center;

      .ec-newsRole__newsDate {
        color: #bcb8b8;
      }

      .ec-newsRole__newsTitle {
        color: #000000;
        font-weight: unset;
      }

      .ec-newsRole__newsCloseBtn {
        color: #898989;
        background: $white-color;
      }
    }

    .more {
      font-size: 16px;
      cursor: pointer;
      color: $green-color;
      float: right;

      &:hover {
        color: $green-color;
      }
    }
  }
}

.ec-newItemRole {
  .ec-newItemRole__listItemBlock {
    @include block-product;
  }

  .ec-recommendItemRole__list{
    .recommendItem-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;

      @include media_mobile {
        display: grid;
        grid-template-columns: repeat(3, 32%);
        gap: 10px;
      }
    }
  }
}


.ec-recommendItemRole {
  padding: 20px 0 40px;
  @include media_desktop {
    padding: 30px 0 30px;
  }

  @include media_middle_large {
    padding: 30px 25px;
  }

  #recommendReadmore {
    float: right;
    font-size: 16px;
    cursor: pointer;
    color: #3a9943;
    margin-right: 10px;
  }
}

.ec-recentlyItemRole {
  @include media_desktop {
    padding: 0 0 30px 0;
  }

  @include media_middle_large {
    padding: 0 25px 30px 25px;
  }
  .delete-history {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-right: 25px;
  }

  .navigator {
    padding: 7px 14px;
  }

  .ec-recentlyItemRole__listItemBlock {
    @include block-product;
    display: block !important;
  }
}

.ec-topicBannerRole {
  padding-bottom: 60px;
  @include media_mobile {
    padding-bottom: 18px;
  }
  .ec-topicBanner__item {
    max-width: 430px;
    @include media_tablet {
      max-width: 350px;
    }
    &:nth-child(2) {
      margin-left: 30px;
      @include media_mobile {
        margin: 10px 10px 0 10px;
      }
    }
    @include media_mobile {
      margin: 10px 10px 0 10px;
    }
  }

  .img-fluid {
    border-radius: 4px;
  }
}

.ec-recommendItemRole {
  .ec-recommendItemRole__wrap {
    @include media_mobile {
      display: block;
    }
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .col-4,
  .col-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  & &__wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  & &__listItemBlock {
    @include block-product;
    display: block !important;
  }
}

.ec-btn-top-wrap {
  text-align: right;
  padding-right: 85px;
  margin-bottom: 5px;
  @include media_mobile {
    padding: 0;
    text-align: right;
    margin-bottom: 18px;
  }
}

.ec-btn-top {
  color: #3A9943 !important;
  font-weight: 700;
  text-decoration: underline !important;
  font-size: 14px;
}

.ec-topSlider {
  @include block-slick-slider;
}

.ec-blogRole {
  padding-bottom: 60px;

  @include media_middle_large {
    padding: 0 25px 60px 25px;
  }
  .blogItem {
    .blog-img {
      width: 100%;
      padding-bottom: 100%;
      background-position: center;
      background-size: cover;
      transition: 0.4s all ease-in-out;
      &:hover {
        opacity: .6;
      }
    }

    .blog-title {
      display: block;
      color: #000;
      font-weight: 400;
      font-size: 14px;
      margin: 8px 0;
    }

    .blog-meta {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
      -moz-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      align-items: center;

      .blog-author {
        color: #C7C7C7;
        font-size: 14px;
        a {
          margin-left: 3px;
          color: #212121;
        }
      }

      .blog-date {
        font-size: 14px;
      }

      .ec-btn-top {
        margin-left: auto;
      }
    }
  }
}
.sale-price {
  text-decoration: line-through;
}
.ec-productRanking {
  padding: 20px 0 40px;
  @include media_desktop {
    padding: 30px 0 30px;
  }

  @include media_middle_large {
    padding: 30px 25px;
  }

  .ec-newItemRole__listItem {
    margin-bottom: 22px;
    padding-left: 10px;
    padding-right: 10px;
    @include media_mobile {
      font-size: 14px;
    }

    .ec-newItemRole__listItemBlock {
      height: 100%;
      position: relative;
      .item-price {
        color: #000000;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Oswald', sans-serif;
        @include media_mobile {
          font-size: 12.5px;
        }
      }
      .item-title {
        margin: 8px 0;
        font-size: 14px;
        @include media_desktop {
          margin: 0 0 8px 0;
        }
      }
      a {
        color: #000000;
      }
      .rank-number {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 13px;
        color: #fff;
        z-index: 1;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FF9E0D;
        opacity: 0.9;
      }
    }
  }
  #ranking_readmore {
    float: right;
    font-size: 16px;
    cursor: pointer;
    color: #3a9943;
    margin-right: 10px;
  }
}

.ec-freeDeliveryBannerRole {
  padding-bottom: 60px;
  @include media_mobile {
    padding-bottom: 18px;
  }
  .ec-freeDeliveryBannerRole__box {
    .ec-topicBanner__item {
      max-width: 890px;
      margin: 0 10px;
      @include media_mobile {
        margin: 10px 10px 0 10px;
      }
    }
  }

  .img-fluid {
    border-radius: 4px;
  }
}

.ec-inviteFriendBannerRole {
  padding-bottom: 60px;
  @include media_mobile {
    padding-bottom: 18px;
  }
  .ec-inviteFriendBannerRole__box {
    .ec-topicBanner__item {
      max-width: 890px;
      margin: 0 10px;
      @include media_mobile {
        margin: 10px 10px 0 10px;
      }
    }
  }

  .img-fluid {
    border-radius: 4px;
  }
}

.ec-instagramRole {
  margin-bottom: 20px;
  .ec-instagramRole__list {
    @include media_desktop {
      padding: 0;
    }
    .ec-instagramRole__list__row {
      display: grid;
      grid-template-columns: repeat(8,1fr);
      padding: 0;

      @include media_middle {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        padding: 0;
      }

      @include media_small {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        padding: 0;
      }

      .instagram-item {
        float: left;
        position: relative;
        list-style-type: none;
        border: 1px solid white;
        border-width: 2px;
        width: 100%;

        &:hover > .popup {
          transform: translate(-50%, 0);
          opacity: 1;
          visibility: unset;
        }

        &:nth-child(1) {
          @include media_desktop {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
          }
        }

        &:nth-child(10) {
          @include media_desktop {
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
          }
          @include media_middle {
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 4;
            grid-row-end: 6;
          }
        }
        @include media_mobile {
          &:nth-child(n + 10) {
            display: none;
          }
        }
        .media-image {
          width: 100%;
        }

        .popup {
          @include media_mobile {
            display: none;
          }
          padding: 1rem;
          background-color: $black-color;
          color: $white-color;
          border-radius: 4px;
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
          position: absolute;
          bottom: calc(100% + 7px);
          left: 50%;
          width: 100%;
          z-index: 10;
          visibility: hidden;
          opacity: 0;
          transform: translate(-50%, 20px);
          transition: 0.5s;
          word-break: break-word;
          &::after {
            content: "";
            display: block;
            width: -1;
            height: 0;
            border: 7px solid transparent;
            border-top-color: $black-color;
            position: absolute;
            top: 100%;
            left: calc(50% - 7px);
          }
        }
      }
    }
  }
}

.event-slider {
  margin: 50px 0 30px 0;
  .main_visual {
    max-width: 750px;
    margin: 0 auto;
  }
}
