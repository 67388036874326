.ec-favoriteRole {
  .ec-favoriteRole__detail {
    @include media_middle_large {
      padding: 0 25px;
    }
    .ec-favoriteRole__itemList {
      .ec-favoriteRole__item {
        .ec-favoriteRole__item__in {
          .reset-btn {
            z-index: 10;
          }
        }
      }
    }
  }
}
