#page_product_list {
	.l-pankz {
		display: none;
	}
}
.ec-listProductHeading {
	text-align: center;
	max-width: 1020px;
	margin: 45px auto 35px auto;
	@include media_mobile() {
		padding-left: 15px;
		padding-right: 15px;
	}
}
.ec-searchnavRole__infos {
	@include media_mobile() {
		padding-left: 0!important;
		padding-right: 0!important;
	}
	.ec-select {
		select {
			@include media_desktop() {
				width: 350px;
				max-width: 100%;
				margin: 0 10px;
			}
			@include media_middle {
				width: 324px;
				margin: 0 10px;
			}
			@include media_mobile() {
				width: calc(50% - 5px);
				float: left;
				+ select {
					margin-left: 10px;
				}
			}
		}
	}
}
