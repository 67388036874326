.ec-layoutRole__header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #FFFFFF;
  z-index: 999;
  .ec-header {
    height: 100px;
    @include media_mobile {
      position: fixed;
      background: white;
      z-index: 100;
      margin: unset !important;
      height: 70px;
    }

    @include media_tablet {
      position: fixed;
      background: white;
      z-index: 100;
      margin: unset !important;
      height: 70px;
    }

    .header-socials {
      border-bottom: 1px solid black;
      padding: 10px 0;
    }

    .navigation {
      height: 100%;
      .ec-headerNavSP {
        @include media_mobile {
          position: relative;
        }
        @include media_tablet {
          position: relative;
        }
        span {
          position: absolute;
          left: 0;
          width: 25px;
          height: 2px;
          background-color: black;
          transition: 0.5s;

          &:nth-child(1) {
            transform: translateY(-7px);
          }
          &:nth-child(2) {
            transform: translateY(7px);
          }
        }
      }

      .ec-headerNavSP.is_active {
        span {
          &:nth-child(1) {
            width: 26px;
            transform: translateY(0) rotate(225deg);
            transition-delay: 0.1s;
          }
          &:nth-child(2) {
            width: 26px;
            transform: translateY(0) rotate(315deg);
            transition-delay: 0.1s;
          }
          &:nth-child(3) {
            width: 26px;
            transform: translateX(-100%);
            opacity: 0;
            transition-delay: 0.1s;
          }
        }
      }

      .logo {
        height: 43px;
        @include media_mobile {
          height: 28px;
          vertical-align: unset;
        }
        @include media_tablet {
          height: 30px;
          vertical-align: unset;
        }
      }

      .middle {
        flex: 0.7;
      }

      .form-control {
        height: 50px !important;
      }

      .search-product-input {
        padding: 0.375rem 2.75rem 0.375rem 0.75rem;
      }

      .reset-btn {
        @include middle-element(-50%, -50%);
        right: 0;
        padding: 0;

        img {
          height: 30px;
        }
      }

      .header-nav {
        .item {
          font-weight: 400;
          color: #212121;
          font-family: "Noto Sans JP", sans-serif;
          margin-right: 8px;

          .icon {
            font-size: 1.2em;
            margin-top: 5px;
          }

          .img-fluid {
            width: 25px;
          }

          a {
            color: #212121;
            &:hover {
              text-decoration: none;
              color: #3A9943;
            }
          }

          .count {
            position: absolute;
            top: -9px;
            font-size: 12px;
            border-radius: 50%;
            color: white;
            background: #D90000;
            width: 17px;
            height: 17px;
            display: flex;
            justify-content: center;
            right: -7px;
            align-items: center;
            @include media_mobile {
              top: -5px;
              right: -7px;
            }
            @include media_tablet {
              top: -7px;
              right: -7px;
            }
          }

          .ec-blockBtn--action {
            color: #ffffff;
            background: #3A9943;
            border-color: #3A9943;
          }
          position: relative;
          .submenu__link {
            display: none;
            z-index: 100;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            position: absolute;
            top: 22px;
            left: 0;
            a {
              &:hover {
                color: #ffffff;
                text-decoration: none;
              }
            }
            .submenu__item {
              position: relative;
              .submenu__child1__box {
                display: none;
                position: absolute;
                top: 0;
                left: 140px;
                z-index: 100;
                width: auto;
                .submenu__child1 {
                  margin-left: 10px;
                  background: #FFFFFF;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                  border-radius: 8px;
                  display: block;
                  padding: 9px 4px;
                  a {
                    display: inline-block;
                    white-space: nowrap;
                    .submenu__btn {
                      margin: 0;
                    }
                  }
                }
              }
              &:hover {
                .submenu__child1__box {
                  display: block;
                }
              }
              .submenu__btn {
                width: 132px;
                height: 32px;
                margin: 5px 4px;
                font-weight: 500;
                font-size: 14px;
                color: #212121;
                padding: 5px 14px;
                &:hover {
                  background: rgba(58, 153, 67, 0.1);
                  border-radius: 4px;
                  color: #3A9943;
                }
              }
            }
          }
          &:hover {
            color: #3A9943;
            .submenu__link {
              display: block;
            }
          }
          &.search-icon {
            .search-icon-btn {
              background: unset;
              border: unset;
              outline: none;
            }
            .search-wrap {
              background-color: #ccc;
              padding: 5px;
              position: absolute;
              bottom: -78px;
              width: 300px;
              right: 0;
              &::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: solid 10px transparent;
                border-bottom-color: #ccc;
                position: absolute;
                bottom: 100%;
                right: 10px;
              }
              .search-box {
                img {
                  height: 20px;
                }
                position: relative;
                .keyword-list {
                  position: absolute;
                  top: 50px;
                  background: white;
                  ul {
                    padding: 0;
                    li {
                      list-style-type: none;
                      padding: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.is-safari {
      .navigation {
        .header-nav {
          .item {
            .submenu__link {
              .submenu__item {
                .submenu__child1__box {
                  top: -40px;
                  left: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
  .ec-categoryNaviRole {
    display: none;
  }
}

.ec-layoutRole {
  .ec-layoutRole__contents {
    @include media_mobile {
      padding-top: 70px;
    }
    @include media_tablet {
      padding-top: 70px;
    }
  }
}

.ec-drawerRole {
  overflow-y: scroll;
  background: black;
  width: 100%;
  height: 85vh;
  transform: translateX(-100%);
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 1;
  transition: transform 0.3s 1ms;
  @include media_desktop() {
    display: none;
  }
  .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #f8f8f8;
  }

  .ec-headerSearch {
    padding: 16px 8px 26px;
    background: #ebebeb;
    color: #636378;
    select {
      width: 100% !important;
    }
    .keyword-list {
      ul {
        padding: 0;
        li {
          list-style-type: none;
          padding: 5px;
          a {
            color: #212121;
          }
          a:hover {
            text-decoration: none;
            color: #3A9943;
          }
        }
      }
    }
  }

  .ec-headerCategoryArea {
    .ec-headerCategoryArea__heading {
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      padding: 1em 10px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      background: #f8f8f8;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ec-itemNav__nav li a {
      border-bottom: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      color: black;
      font-weight: normal;
      background: #f8f8f8;
    }

    .ec-itemNav__nav li ul li a {
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      font-weight: normal;
      background: white;
    }

    .ec-itemNav__nav > li:hover > a {
      background: #f8f8f8;
    }

    .ec-itemNav__nav > li:hover li:hover > a {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li a {
      padding-left: 40px;
      color: black;
      background: white;
    }

    .ec-itemNav__nav li:hover ul li ul li a:hover {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li ul li a {
      padding-left: 60px;
      font-weight: normal;
    }
  }
  .ec-headerLinkArea {
    background: black;

    .ec-headerLink__list {
      border-top: 1px solid #ccc;
    }

    .ec-headerLink__item {
      display: block;
      border-bottom: 1px solid #ccc;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;
      color: white;
    }
    .ec-headerLink__icon {
      display: inline-block;
      width: 28px;
      font-size: 17px;
    }
  }
}
.ec-drawerRoleClose.is_active {
  display: none;
}
