@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/

/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress{
  margin: 0 auto;
  padding: 8px 0 16px;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 600px;
  list-style: none;
  @include media_desktop {
    margin-bottom: 35px;
    padding: 0;
  }

  & &__item{
    display:table-cell;
    position: relative;
    font-size: 14px;
    text-align: center;
    z-index: 10;

    &:after {
      content: '';
      position: absolute;
      display: block;
      background: #d9d9d9;
      width: 100%;
      height: 0.125em;
      top: 1.25em;
      left: 50%;
      margin-left: 1.5em\9;
      z-index: -1;
    }
    &:last-child:after {
      display: none;
    }
    &.is-complete {
      &:after {
        background: #3a9943;
      }
      .ec-progress__number {
        color: #ffffff;
        background: #3a9943;
      }
      .ec-progress__label {
        color: #3a9943;
      }
    }
  }
  & &__number{
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    background: #eaeaea;
    color: #212121;
    top: 0;
    left: 18px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    @include media_desktop(){
      line-height: 42px;
      width: 42px;
      height: 42px;
      font-size: 20px;
    }
  }
  & &__label {
    font-size: 14px;
    font-weight: 500;
    color: #212121;
  }
}



/*
カートナビゲーション

カートナビゲーションを表示します。　カートに追加された商品の個数も表示します。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/

.ec-headerRole__cart {
  :hover {
    color: #000;
  }
}

.ec-cartNaviWrap{
  @include media_desktop {
    position: relative;
  }
}
.ec-cartNavi{
  display: inline-block;
  width: auto;
  color: black;
  background: transparent;
  @include media_desktop {
    display: flex;
    justify-content: space-between;
    border-radius: 99999px;
    box-sizing: border-box;
    width: auto;
    white-space: nowrap;
    cursor: pointer;
    background: #F8F8F8;
  }

  & &__icon {
    display: inline-block;
    font-size: 20px;
    @include fadeIn(inline-block,200ms);
    position: relative;

  }
  & &__badge{
    display: inline-block;
    border-radius: 99999px;
    box-sizing: border-box;
    padding: 5px;
    height: 17px;
    font-size: 10px;
    line-height: 0.7;
    vertical-align: top;
    color: #fff;
    text-align: left;
    white-space: nowrap;
    background-color: #DE5D50;
    position: absolute;
    left: 60%;
    top: -10px;
    @include media_desktop {
      display: inline-block;
      min-width: 17px;
      position: relative;
      left: 0;
      top: 0;
    }
  }
  & &__price{
    display: none;

    @include media_desktop {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      vertical-align: middle;
    }
  }
}
.ec-cartNavi.is-active {

  .ec-cartNavi__icon {
    &:before {
      content: "\f00d";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }
  }
  .ec-cartNavi__badge{
    display: none;
    @include media_desktop {
      display: none;
    }

  }
}


/*
カートナビゲーションのポップアップ(商品詳細)

カートナビゲーションのポップアップを表示します。カートに追加された商品の詳細が表示されます。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='close')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
// .ec-cartNaviIsset {
//   display: none;
//   width: 320px;
//   text-align: center;
//   background: #ffffff;
//   box-sizing: border-box;
//   padding: 20px;
//   z-index: 999;
//   position: absolute;
//   max-height: 90vh;
//   overflow-y: scroll;
//   filter: drop-shadow(0 0 1px #ddd) drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
//   font-size: 13px;

//   @include media_mobile {
//     right: 0;
//   }

//   @include media_desktop {
//     right: 10px;
//     margin-top: 10px;
//   }

//   .ec-cartNaviIsset__cart {
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 20px;
//     padding-bottom: 20px;
//     border-bottom: solid 1px #eee;

//     .ec-cartNaviIsset__cartImage {
//       flex: 0 0 90px;
//       order: 2;
//       position: relative;
//     }

//     .ec-cartNaviIsset__cartContent {
//       flex: 0 1 auto;
//       order: 1;
//       padding-right: 10px;

//       .ec-cartNaviIsset__cartContentTitle {
//         margin-bottom: 0.5em;
//         font-weight: 700;
//       }

//       .ec-cartNaviIsset__cartContentClass {
//         margin: 0;
//         margin-left: 10px;
//         margin-bottom: 0.5em;
//         font-size: 0.85em;
//       }

//       .ec-cartNaviIsset__cartContentPrice {
//         display: inline-block;
//         vertical-align: bottom;
//         margin-left: 10px;
//       }
//     }
//   }

//   .ec-cartNaviIsset__TotalPrice {
//     margin: 0;
//     display: flex;
//     justify-content: space-between;
//     font-size: 1.1em;
//     font-weight: 600;
//   }

//   #go-to-payment {
//     background-color: #000;
//     border-color: #000;
//     &:hover {
//       color: #fff;
//     }
//   }

//   #close-cart {
//     &:hover {
//       color: #000;
//     }
//   }
// }

// .ec-cartNaviIsset.is-active {
//   display: block;
// }



/*
カートナビゲーションのポップアップ(商品なし)

カートナビゲーションのポップアップを表示します。商品が登録されていない場合の表示です。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='cart')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/


.ec-cartNaviNull {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  right: 0;

  @include media_desktop {
    margin-top: 10px;
    min-width: 256px;
    max-width:256px;

    &::before {
      display: inline-block;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8.5px 10px 8.5px;
      border-color: transparent transparent #f8f8f8 transparent;
      position: absolute;
      top: -9px;

    }
  }

  & &__message {
    border: 1px solid #D9D9D9;
    padding: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #3A9943;
    p {
      margin: 0;
      font-size: 14px;
       &:hover {
        color: white;
      }
    }
  }
}

.ec-cartNaviNull.is-active {
  display: block;
  @include media_mobile {
    position: fixed;
  }
}



/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox{
  background:#F3F3F3;
  padding: 16px;
  margin-bottom: 16px;
  & &__spec{
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom:8px;
    dt{
      font-weight: normal;
      text-align: left;
    }
    dd{
      text-align: right;
    }
    & &__specTotal {
      color: $clrRed;
    }
  }
  & &__total{
    border-top: 1px dotted #ccc;
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
  }
  & &__paymentTotal{
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    .ec-totalBox__price,
    .ec-totalBox__taxLabel{
        color: $clrRed;
    }
  }
  & &__price{
    margin-left: 16px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    @include media_desktop {
      font-size: 20px;
    }
  }
  & &__taxLabel {
    margin-left: 8px;
    font-size: 12px;
    @include media_desktop {
      font-size: 14px;
    }
  }
  & &__taxRate {
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom:8px;
    font-size: 10px;
    @include media_desktop {
      font-size: 12px;
    }
    dt{
      font-weight: normal;
      text-align: left;
      margin-right: 8px;
    }
    dd{
      text-align: right;
    }
  }
  & &__pointBlock{
    padding: 20px 0 5px 0;
    margin-bottom: 20px;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }
  & &__btn {
    @include reset_link();
    color: #fff;
    .ec-blockBtn--action {
      font-size: 16px;
      font-weight: bold;
    }
    .ec-blockBtn--cancel {
      margin-top: 8px;
    }
  }
}
