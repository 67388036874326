.contact_page {
  .ec-layoutRole__contents {
    max-width: 100%;
    background-color: #f8f8f8;
  }
  .ec-contactRole, .ec-contactConfirmRole {
    padding: 0;
    .ec-borderedDefs {
      border: none;
      dt {
        padding-top: 0;
      }
      dd {
        width: 100%;
      }

      dl {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #ffffff;
        box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.04);
        margin-bottom: 12px;
        padding: 1.2em 2em 0.8em 2em;
        input[type='text'], input[type='email'],
        input[type='tel'], select,
        input[type='password'] {
          @include register_input;
          padding-left: 10px;
        }
        textarea {
            margin-top: 10px !important;
            min-height: 150px;
            width: 100%;
            margin: 0;
            border-radius: 4px;
            border: 1px solid #dedede;
        }
      }
    }
    .text-notice {
        color: #e90000;
    }
    .ec-main-btn {
        padding: 10px 72px;
    }
    .ec-cancel-btn {
        margin-right: 15px;
        padding: 10px 72px;
    }
  }
}
