.product-detail {
  .ec-layoutRole__mainBottom {
    @include media_mobile {
      display: none;
    }
    @include media_tablet {
      display: none;
    }
  }
  .l-pankz {
    display: none;
  }
  .ec-productRole {
    .ec-sliderItemRole {
      position: relative;
      .item-inner {
        position: relative;

        .btn-like {
          position: absolute;
          top: 10px;
          right: 10px;
          border: none;
          z-index: 1;
          background: unset;
          padding: unset;
          img {
            width: 24px;
          }
        }
        .icon-sale {
          position: absolute;
          top: 0;
          left: 0;
          .corner {
            border-top: 120px solid #8b1ad1;
            border-right: 120px solid transparent;
            background: transparent;
            z-index: 5;
            position: absolute;
            opacity: 0.7;
            @include media_mobile {
              border-top: 80px solid #8b1ad1;
              border-right: 80px solid transparent;
            }
          }
          .text {
            position: absolute;
            top: 20px;
            left: 11px;
            font-size: 23px;
            color: $white-color;
            text-transform: uppercase;
            font-weight: bold;
            z-index: 10;
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -sand-transform: rotate(-45deg);
            transform: rotate(-45deg);
            @include media_mobile {
              top: 15px;
              left: 8px;
              font-size: 15px;
            }
          }
        }
        
        .icon-sale-point {
          position: absolute;
          top: 0;
          left: 0;
          .corner {
            border-top: 120px solid #ffa047;
            border-right: 120px solid transparent;
            background: transparent;
            z-index: 5;
            position: absolute;
            opacity: 0.9;
            @include media_mobile {
              border-top: 80px solid #ffa047;
              border-right: 80px solid transparent;
            }
          }
          .text {
            position: absolute;
            text-align: center;
            line-height: 1;
            top: 20px;
            left: 9px;
            font-size: 19px;
            color: $white-color;
            text-transform: uppercase;
            font-weight: bold;
            z-index: 10;
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -sand-transform: rotate(-45deg);
            transform: rotate(-45deg);
            @include media_mobile {
              top: 13px;
              left: 5px !important;
              font-size: 13px;
            }
          }
        }
        .icon-new{
          position: absolute;
          top: 0;
          left: 0;
          .corner {
            border-top: 120px solid #c51a14;
            border-right: 120px solid transparent;
            background: transparent;
            z-index: 5;
            position: absolute;
            opacity: 0.7;
            @include media_mobile {
              border-top: 80px solid #c51a14;
              border-right: 80px solid transparent;
            }
          }
          .text {
            position: absolute;
            top: 20px;
            left: 11px;
            font-size: 23px;
            color: $white-color;
            text-transform: uppercase;
            font-weight: bold;
            z-index: 10;
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -sand-transform: rotate(-45deg);
            transform: rotate(-45deg);
            @include media_mobile {
              top: 15px;
              left: 8px;
              font-size: 15px;
            }
          }
        }
        .ec-sale-point {
          font-weight: 500;
          font-size: 23px;
          line-height: 43px;
          color: #D90000;
          font-family: 'Oswald', sans-serif;
        }
      }
    }
    .ec-productRole__profile {
      .ec-productRole__title {
        .ec-headingTitle {
          font-weight: 500;
          font-size: 24px;
          line-height: 35px;
          color: #000000;
          @include media_mobile {
            font-size: 20px;
          }
        }
      }
      .ec-productRole__price {
        border-bottom: unset;
        .ec-price {
          .ec-price__price {
            font-weight: 500;
            font-size: 23px;
            line-height: 43px;
            color: #D90000;
            font-family: 'Oswald', sans-serif;
            @include media_mobile {
              font-size: 20px;
            }
          }
        }
      }
      .product-class {
        @include media_desktop {
          width: 70%;
        }
        @include media_tablet {
          width: 100%;
        }
        .product-class__items {
          .product-class__item {
            margin-top: 20px;
            &:nth-child(2) {
              margin-top: 10px;
            }
            .product-class-name {
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: #000000;
              width: 10%;
              @include media_mobile {
                font-size: 14px;
              }
            }
            .product-class-price {
              font-weight: 500;
              font-size: 23px;
              line-height: 43px;
              color: #D90000;
              width: 30%;
              font-family: 'Oswald', sans-serif;
              @include media_mobile {
                font-size: 20px;
              }
            }
            .product-class-quantity {
              width: 25%;
              .quantity-pc {
                width: 80%;
                height: 40px !important;
              }
              .quantity-label {
                padding-left: 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                color: #000000;
                &.quantity-label-with-point {

                }
              }
            }
            .product-class-add-cart-btn {
              font-weight: 400;
              font-size: 16px;
              background: #3A9943;
              border: 1px solid #3A9943;
              display: flex;
              justify-content: center;
              align-items: center;
              @include media_mobile {
                font-size: 14px;
              }
            }
          }
        }
        .ec-blockBtn--action {
          background-color: $gray-color;
          border-color: $gray-color;
        }
        .out-stock-btn {
          border-color: #DE5D50;
          background-color: #ffffff;
          color: #585858;
          font-weight: normal;
        }
      }
      .ec-productRole__code {
        border-bottom: unset;
        padding-bottom: 0;
      }
      .ec-productRole__tags {
        border-bottom: unset;
        padding-bottom: 0;
      }
      .ec-productRole__category {
        border-bottom: unset;
        padding-top: 0;
      }
      .action-box {
        .create-post-btn {
          width: 146px;
          height: 40px;
          font-size: 16px;
          line-height: 36px;
          font-weight: 400;
          color: #585858;
          background-color: #ffffff;
          margin-right: 12px;
          border: 1px solid #585858;
          &:hover {
            color: #ffffff;
            background: #3A9943;
            border: 1px solid #3A9943;
          }
        }
        .ec-productRole__btn {
          min-width: unset;
          .contact {
            width: 146px;
            height: 40px;
            font-size: 16px;
            line-height: 36px;
            font-weight: 400;
            color: #585858;
            background-color: #ffffff;
            &:hover {
              color: #ffffff;
              background: #30B6D3;
              border: 1px solid #30B6D3;
            }
          }
        }
      }
    }
  }
  .nav-tabs {
    border: unset;
    justify-content: center;
    margin-bottom: 20px;
    .nav-item {
      .nav-link {
        border: unset;
        padding: unset;
        margin-right: 70px;
        color: #828282;
        @include media_mobile {
          margin-right: 30px;
        }
        &.active {
          color: #3A9943;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            height: 1px;
            background: #3a9943;
            width: 100%;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 50%);
          }
        }
      }
    }
  }
}

.product-list {
  .ec-blockBtn--action {
    &:disabled {
      background: $gray-color;
      border: $gray-color;
    }
  }
  .add-cart {
    &.ec-cartBtn {
      margin-bottom: 0!important;
    }
  }
  .ec-shelfGrid {
    @include media_desktop() {
      justify-content: start;
    }
    @include media_middle_large {
      padding: 0 25px;
    }
    .ec-shelfGrid__item {
      width: 100%;
      margin-left: 20px;
      @include media_desktop() {
        width: calc(25% - 15px);
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }
      @include media_small() {
        width: calc((100% - 20px) / 2);
        margin-left: 0;
      }
      .item_inner {
        @include block-product;
        @include media_small() {
          .p-3 {
            padding: 10px !important;
          }
        }
        height: auto !important;
      }
    }
  }
}
