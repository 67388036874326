@import "../mixins/media";
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole{
  margin-bottom: 0;
  padding: 0;
  @include media_desktop {
    @include container;
  }
  & &__infos{
    @include container;
    margin-bottom: 20px;
  }

  & &__counter{
    display: none;
    margin-bottom: 16px;
    width: 100%;
    @include media_desktop {
      margin-bottom: 0;
      width: 50%;
    }
  }

  & &__actions{
    display: flex;
    justify-content: center;
  }


}
