@import "../mixins/media";
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole{
  @include container;
  margin-bottom: 35px;
  
  img {
    width: 100%;
    height: auto;
  }

  ul{
    padding: 0;
    list-style: none;
  }
}
.ec-sliderItemRole{
  @include container;
  margin-bottom: 24px;
  ul{
    padding: 0;
    list-style: none;
  }
  .item_nav {
    display: none;
    @include media_desktop {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

  }
  .slideThumb{
    margin-bottom: 25px;
    width: 33%;
    opacity: .8;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 1;
    }
    img {
      width: 80%;
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 10px;
  padding: 0;
  cursor: pointer;
  &:only-child {
    display: none;
  }
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  content: " ";
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  text-align: center;
  background-color: #D9D9D9;
  border-radius: 50%;
}
.slick-dots li.slick-active button:before {
  background-color: #3A9943;
}
.slick-dots li button.thumbnail img {
  width: 0;
  height: 0;
}
