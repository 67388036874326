.blog_detail {
  max-width: 900px;
  margin: 0 auto;
  .blog_detail_content {
    .blog_image {
      .img-box {
        &:after {
          padding-bottom: 45%;
        }
      }
    }
  }
}
