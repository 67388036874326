.font-oswald {
    font-family: 'Oswald', sans-serif;
}
.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-20 {
    font-size: 20px;
}

.pl-30 {
    padding-left: 30px !important;
}

.text-main {
    color: $green-color  !important;
}

.required-abbr {
    color: #e40303;
}

button {
    cursor: pointer;
}
.btn {
    border-radius: 0 !important;
}
.button {
    cursor: pointer;
    outline: none !important;
}

.button-hover-green {
    &:hover {
        background-color: $green-color;
        border: none;
    }
}

.minus-icon {
    font-size: 1.2em;
    line-height: 0;
    font-weight: bolder;
    text-align: center;
    vertical-align: middle;
}

.reset-btn {
    outline: none;
    border: none;
    background: transparent;
}

input[type="password"], input[type="text"], select {
    &.form-control {
        border-radius: 4px !important;
        border: 1px solid #dedede !important;
        height: 40px !important;
        color: $black-color;
        &::placeholder {
            color: #adadad;
            opacity: 1;
        }
    }
}
.error {
    input {
        &:not([type='checkbox']) {
            border: 1px solid #CF3F34 !important;
            background: #FDF1F0;
        }
    }
    .ec-errorMessage {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
        color: #DE5D50;
    }
}
.ec-main-btn {
    @extend .button;
    background: $green-color;
    border: 1px solid $green-color;
    color: $white-color !important;
    font-size: 16px;
    text-align: center;
}
.ec-cancel-btn {
    @extend .button;
    background: $gray-color;
    border: 1px solid $gray-color;
    color: $white-color !important;;
    font-size: 16px;
    text-align: center;
}
.ec-text-main {
    color: $green-color !important;
}
.ec-text-gray {
    color: #8a8a8a !important;
}
.ec-text-red {
    color: #d90000 !important;
}
.text-dark {
    color: #212121 !important;
}
.mb-40 {
  margin-bottom: 40px;
}

.has-stock-label {
  display: inline-block;
  border: 1px solid #666666;
  padding: 2px 8px;
  margin-bottom: 2px;
  color: #3A9943;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.no-stock-label {
  display: inline-block;
  border: 1px solid #666666;
  padding: 2px 8px;
  margin-bottom: 2px;
  color: #666666;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.img-box {
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .square {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    .item-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.4s all ease-in-out;
      &:hover {
        opacity: .6;
      }
    }
  }
}

.icon-new{
  position: absolute;
  top: 0;
  left: 0;
  .corner {
    border-top: 65px solid #c51a14;
    border-right: 65px solid transparent;
    background: transparent;
    z-index: 5;
    position: absolute;
    opacity: 0.7;
    @include media_mobile {
      border-top: 50px solid #c51a14;
      border-right: 50px solid transparent;
    }
  }
  .text {
    position: absolute;
    top: 11px;
    left: 3px;
    font-size: 14px;
    color: $white-color;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 10;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -sand-transform: rotate(-45deg);
    transform: rotate(-45deg);
    @include media_mobile {
      top: 9px;
      left: 4px;
      font-size: 11px;
    }
  }
}

.submenu {
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  .submenu__link {
    text-decoration: none;
    margin-left: 2px;
    &:first-child {
      margin-left: unset;
    }
    .submenu__btn {
      width: 166px;
      height: 40px;
      line-height: 38px;
      font-size: 24px;
      background: #FFFFFF;
      border: 1px solid #033F09;
      font-weight: 400;
      color: #033F09;
      &.active {
        background: #033F09;
        color: #FFFFFF;
      }
      &:hover {
        background: #033F09;
        color: #FFFFFF;
      }
      @include media_mobile {
        width: 90px;
        height: 35px;
        line-height: 33px;
        font-size: 16px;
      }
    }
  }
  .post-menu {
    position: relative;
    cursor: pointer;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .post-submenu {
      display: none;
      position: absolute;
      left: -2px;
      cursor: pointer;
      z-index: 2;
      &:hover {
        background: #033F09;
        color: #FFFFFF;
      }
    }
    &:hover {
      .post-submenu {
        display: block;
      }
    }
  }
}

.ec-layoutRole__contents {
  padding-top: 100px;
}

@mixin checkboxAnimation {
    input[type="checkbox"] {
        display: grid;
        appearance: none;
        background-color: white;
        color: currentColor;
        width: 1.25em;
        height: 1.25em;
        border: 2px solid currentColor;
        border-radius: 6px;
        margin: 0 10px 0 0;

        &::before {
            content: "";
            width: 0.7em;
            height: 0.7em;
            margin: auto auto;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            border-radius: 20%;
            background-color: $white-color;
            transform-origin: bottom left;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }

        &:checked {
            background-color: $green-color;
        }

        &:checked::before {
            transform: scale(1);
        }
    }
}

.ec-secHeading {
    text-align: center;
    @include media_mobile() {
        margin-bottom: 25px;
    }
    .ec-secHeading__ss {
        font-weight: unset;
        font-size: 27px;
        position: relative;
        @include media_mobile() {
            font-size: 20px;
            &::after {
                width: 49px;
            }
        }
        &::after {
            content: "";
            position: absolute;
            height: 3px;
            background: #3a9943;
            width: 63px;
            bottom: -8px;
            left: 50%;
            transform: translate(-50%, 50%);
        }
    }
}

.ec-categoryNaviRole {
    padding: 30px 0 0 0;

    .ec-itemNav__nav {
        display: flex;
        justify-content: space-between;
    }
}

@mixin checkedRadioAnimation {

    // radio
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: relative;
        left: -999999em;
        display: none;
    }

    [type="radio"]:checked+label,
    [type="radio"]:not(:checked)+label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    [type="radio"]:checked+label:before,
    [type="radio"]:not(:checked)+label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 2px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }

    [type="radio"]:checked+label:after,
    [type="radio"]:not(:checked)+label:after {
        content: "";
        width: 12px;
        height: 12px;
        background: $green-color;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.radio {
    height: 100%;
    margin-right: 3em;
    gap: 10px;
    @include checkedRadioAnimation;
}
.pc_none {
    @include media_desktop {
        display: none!important;
    }
}
.sp_none {
    @include media_mobile {
        display: none!important;
    }
}

.tl_none {
  @include media_tablet {
    display: none!important;
  }
}

.ec-modal {
    .ec-cancel-btn {
        padding: 10px 16px;
    }
    .ec-main-btn {
        color: $white-color;
        padding: 10px 16px;
        text-decoration: none;
    }
}

.w-20 {
  width: 20px !important;
}

.more {
  color: $green-color;
  &:hover {
    color: $green-color;
  }
}

.forum-breadcrumb {
  padding: 0 15px;
  font-size: 14px;
  color: #414141;
  a {
    font-size: 14px;
    color: #414141;
  }
}

#nav_mypage_sln_edit_card {
  display: none;
}

.icon-sale {
  position: absolute;
  top: 0;
  left: 0;
  .corner {
    border-top: 65px solid #8b1ad1;
    border-right: 65px solid transparent;
    background: transparent;
    z-index: 5;
    position: absolute;
    opacity: 0.7;
    @include media_mobile {
      border-top: 50px solid #8b1ad1;
      border-right: 50px solid transparent;
    }
  }
  .text {
    position: absolute;
    top: 11px;
    left: 3px;
    font-size: 14px;
    color: $white-color;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 10;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -sand-transform: rotate(-45deg);
    transform: rotate(-45deg);
    @include media_mobile {
      top: 9px;
      left: 4px;
      font-size: 11px;
    }
  }
}

.icon-sale-point {
  position: absolute;
  top: 0;
  left: 0;
  .corner {
    border-top: 65px solid #ffa047;
    border-right: 65px solid transparent;
    background: transparent;
    z-index: 5;
    position: absolute;
    opacity: 0.9;
    @include media_mobile {
      border-top: 50px solid #ffa047;
      border-right: 50px solid transparent;
    }
  }
  .text {
    position: absolute;
    text-align: center;
    line-height: 1;
    top: 12px;
    left: 6px;
    font-size: 10px;
    color: $white-color;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 10;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -sand-transform: rotate(-45deg);
    transform: rotate(-45deg);
    @include media_mobile {
      top: 8px;
      left: 4px !important;
      font-size: 8px;
    }
  }
}
.ec-sale-point {
  font-weight: 500;
  font-size: 23px;
  line-height: 43px;
  color: #D90000;
  font-family: 'Oswald', sans-serif;
}

.list-product-show-point {
  display: flex;
  .show-point-up {
    padding-left: 5px;
    color: red !important;
    @include media_mobile {
      padding-left: unset;
    }
  }
  @include media_mobile {
    display: block;
  }
}