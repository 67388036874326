.modal-dialog {
    .modal-header {
        border-bottom: none;
        .close {
            font-weight: normal;
            opacity: 1;
        }
    }
    .modal-footer {
        padding-top: 0;
        border-top: none;
    }
}