.label-choose-file {
  padding: 0 10px;
  background: #616161;
  display: table;
  color: #fff;
  cursor: pointer;
  margin: 5px 0;
}
.label-choose-file input[type="file"] {
  display: none;
}
.image-display {
  max-width: 200px;
}
.image-display-100 {
  width: 100px;
}
.image-display-200 {
  width: 200px;
}
.image-display-300 {
  width: 300px;
}
.image-display-400 {
  width: 400px;
}
.image-display-500 {
  width: 500px;
}
