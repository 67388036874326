.ec-footer {
  .sp-center {
    @include media_mobile {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .sp-mb-20 {
    @include media_mobile {
      margin-bottom: 20px;
    }
  }
  background: #000000;
  margin-top: 50px;
  .border-top {
    font-size: 14px;
    .logo-footer {
      @include media_mobile {
        text-align: center;
      }
      .logo {
        height: 45px;
        margin-bottom: 24px;
        @include media_mobile {
          height: 30px;
        }
      }
    }
  }
  .guide {
    background: #1f1f1f;
    padding: 30px 0;
    .item {
      display: flex;
      align-items: center;
      padding: 0 20px;
      @include media_mobile {
        padding: 0 10px;
        margin-bottom: 20px;
      }
      height: 100%;
      &.left-border {
        border-left: solid 1px rgba(255, 255, 255, 0.2);
        @include media_mobile {
          border-left: none;
        }
      }
      img {
        margin-right: 20px;
        @include media_mobile {
          width: 28px;
        }
      }
      h3 {
        font-family: "Oswald", "Noto Sans JP", sans-serif;
        font-weight: 400;
        font-size: 15px;
        color: #fff;
        margin: 0;
        margin-bottom: 0.5em;
      }
      p {
        font-size: 13px;
        color: #aaaaaa;
      }
    }
  }
  .info {
    padding: 23px 0 40px 0;
    .footer-socials {
      @include media_mobile {
        padding: 0;
      }
      list-style-type: none;
      margin: 2em 0;
      gap: 3em;
      padding: 0 15px;
      li {
        display: inline-block;
        width: 50px;
        height: 30px;
        text-align: center;
        a {
          margin: auto 0;
          color: $white-color ;
          font-size: 1.6em;
        img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .footer-menu {
      display: flex;
      justify-content: center;
      .category {
        text-align: left;
        @include media_mobile {
          text-align: center;
        }
      }
    }
    .footer-calendar {
      display: flex;
      justify-content: center;
      .ec-calendarSlider {
        width: 300px;
        @include media_middle {
          width: 250px;
        }
        .slick-arrow {
          width: 25px;
          height: 25px;
          background-color: unset;
          position: absolute;
          text-indent: -9999em;
          outline: 0;
          border: 0;
          z-index: 10;
          &.slick-next {
            right: 0;
            top: 0;
            background-image: url("../img/common/next.png");
            background-repeat: no-repeat;
            background-position: center center;
          }
          &.slick-prev {
            left: 0;
            top: 0;
            background-image: url("../img/common/previous.png");
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }
  }

  .logo {
    height: 45px;
  }

  .col-title {
    line-height: 60px;
  }

  .category {
    a {
      color: $white-color !important;
    }
  }
  .copyright {
    padding: 20px 0;
  }
  .apg_sc_table {
    width: 100%;
    color: $white-color;
    tr {
      &:not(:first-child) {
        border-bottom: 1px solid $white-color;
      }
    }
    .apg_sc_header {
      text-align: center;
      padding-bottom: 10px;
    }
    .apg_sc_holiday {
      color: #d42424;
    }
  }
}
