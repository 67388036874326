@import url("../../../../../node_modules/normalize.css/normalize.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

body {
  font-family: "Noto Sans JP", sans-serif;
  color: #585858;
  transition: z-index 0ms 5.28455ms;
  background: #f6f6f6;
  margin: 0;
}

a {
  text-decoration: none;
}

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0;
}

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

@import "component/1.1.heading";
@import "component/1.2.typo";
@import "component/1.3.list";
@import "component/2.1.buttonsize";
@import "component/2.2.closebutton.scss";
@import "component/2.3.otherbutton";
@import "component/3.1.inputText";
@import "component/3.2.inputMisc";
@import "component/3.3.form";
@import "component/4.1.icon";
@import "component/5.1.grid";
@import "component/5.2.layout";
@import "component/6.1.login";
@import "component/7.1.itembanner";
@import "component/7.2.search";
@import "component/7.3.cart";
@import "component/8.1.info";
@import "component/8.2.banner";
@import "component/9.1.mypage";
@import "project/11.1.role";
@import "project/11.2.header";
@import "project/11.3.footer";
@import "project/12.1.slider";
@import "project/12.2.eyecatch";
@import "project/12.3.button";
@import "project/12.4.heading";
@import "project/12.5.topics";
@import "project/12.6.newItem";
@import "project/12.7.category";
@import "project/12.8.news";
@import "project/13.1.searchnav";
@import "project/13.2.shelf";
@import "project/13.3.pager";
@import "project/13.4.cartModal";
@import "project/14.1.product";
@import "project/15.1.cart";
@import "project/15.2.order";
@import "project/16.1.history";
@import "project/16.2.historyDetail";
@import "project/17.1.address";
@import "project/18.1.password";
@import "project/19.1.register";
@import "project/19.2.contact";
@import "project/19.3.customer";
@import "project/20.1.404";
@import "project/21.1.withdraw";
@import "project/22.1.editComplete";
@import "project/23.1.listProduct";
@import "project/24.1.blog";

// Customize
@import "customize/variables";
@import "customize/mixins";
@import "customize/modal";
@import "customize/common";
@import "customize/login";
@import "customize/header";
@import "customize/top";
@import "customize/register";
@import "customize/footer";
@import "customize/post";
@import "customize/product";
@import "customize/contact";
@import "customize/forum";
@import "customize/mypage/navi";
@import "customize/mypage/favorite";
@import "customize/cart";
@import "customize/uploadImage";
@import "customize/static";
@import "customize/mypage/delivery";
@import "customize/blog";
@import "customize/sln_card_payment";
@import "customize/mypage/change";
@import "customize/mypage/sln_edit_card";
@import "customize/forgot";
@import "customize/mypage/post";
