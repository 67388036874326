.faq_page {
    font-size: 14px;
    .ec-secHeading {
        margin-top: 50px;
    }
    .ec-faqRole__faq {
        padding-bottom: 7px;
        &:not(:last-child) {
            margin-bottom: 30px;
            border-bottom: 1px solid #dedede;
        }
        .item {
            margin-top: 10px;
            .li-icon {
                margin-right: 5px;
                img {
                    max-width: unset;
                    width: 25px !important;
                }
            }
        }
    }
    .category-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
        color: $green-color;
    }
}
.static_page {
    font-size: 14px;
    .ec-secHeading {
        margin-top: 50px;
        @include media_small {
            margin-top: 10px;
        }
    }
  &#page_help_privacy {
    .ec-secHeading {
      margin-bottom: 70px
    }
  }
}
.flow {
    .step {
        text-align: center;
        position: relative;
        font-size: 18px;
        color: $green-color;
        background: #f7f7f7;
        margin-bottom: 15px;
        .fa-angle-right {
            @include middle-element(50%, -50%);
            right: -14px;
            @include media_small {
                display: none;
            }
        }
    }
}
.guide_page {
    .box {
      box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.04);
      padding: 20px 10px;
      p {
        line-height: 1.6;
      }
      .ec-link {
        color: $white-color;
        background: $green-color;
        padding: 3px 8px;
      }
    }
    ul {
        padding: 0;
        li {
            list-style-type: none;
        }
    }
    .card {
        margin-top: 5px;
        .header {
            background-color: rgba(0,0,0,.03);
            border-bottom: 1px solid rgba(0,0,0,.125);
        }
        .btn-link {
            text-decoration: none;
            color: $green-color;
            text-align: left;
            white-space: normal;
        }
        .card-body {
            .text-center > p {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }
    }
}
