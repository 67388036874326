.c-list_blog {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    .c-blog-item {
        width: 30%;
        margin-right: 5%;
        margin-bottom: 50px;

        @include media_mobile {
            width: 100%;
            margin-right: unset;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        .img-box {
            position: relative;
            overflow: hidden;
            &:after {
                content: "";
                display: block;
                padding-bottom: 55%;
            }
            .square {
                position: absolute;
                width: 100%;
                top: 0;
                bottom: 0;
                .post-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.4s all ease-in-out;
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
    .blog-content {
        padding: 20px 10px;
    }
    .blog-title {
        font-size: 14px;
        margin: 0 0 10px;
        a {
            color: #000000;
        }
    }
    .blog-meta {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        align-items: center;
        font-size: 14px;
        .blog-author {
            color: #C7C7C7;
            a {
                margin-left: 3px;
                color: #212121;
            }
        }
        .blog-date {
            color: #979797;
            padding-left: 9px;
            margin-left: 6px;
            position: relative;
            &:before {
                content: '';
                height: 3px;
                width: 3px;
                background: #212121;
                position: absolute;
                left: 0;
                top: 50%;
            }
        }
        .ec-btn-top {
            margin-left: auto;
        }
    }
}

.blog_page {
    .ec-secHeading {
        margin-top: 50px;
    }
    .content {
        max-width: 900px;
        margin: 0 auto;
    }
    .blog_detail {
        .blog_title {
            margin: 25px 0;
            font-size: 18px;
            font-weight: 700;
        }
        .post_meta {
            margin-bottom: 32px;
            .date {
                color: #A7A7A7;
                font-size: 12px;
            }
        }
        .blog_image {
            margin-bottom: 25px;
        }
        .blog_content {
            color: #747474;
            font-size: 14px;
            line-height: 1.65;
        }
    }
    .blog_detail_content {
        padding-bottom: 30px;
        border-bottom: 1px solid #d9d9d9;
    }
}
