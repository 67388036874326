@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/

.ec-newItemRole {
  padding: 40px 0;

  @include media_desktop {
    padding: 60px 0;
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
    .col-3 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  & &__list {
    display: flex;
    flex-wrap: wrap;

    @include media_desktop {
      flex-wrap: nowrap;
    }

  }
  & &__listItem {
    margin-bottom: 4%;
    width: 48%;
    height: auto;
    a {
      color: black;
    }

    @include media_desktop {
      margin-bottom: 15px;
      width: calc(100% / 4);

      &:not(:last-of-type){
        margin-right: 30px;
      }
    }

    &:nth-child(odd){
      margin-right: 4%;

      @include media_desktop {
        margin-right: 30px;
      }
    }
  }
  & &__listItemHeading {
    margin-top: calc(45% - 20px);
  }

}
