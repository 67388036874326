.button {
  cursor: pointer;
}

@mixin middle-element($x, $y) {
  position: absolute;
  top: 50%;
  transform: translate($x, $y);
}

@mixin media_middle() {
  @media only screen and (min-width: 768px) and (max-width: 1279px) {
    @content;
  }
}

@mixin media_small() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin media_middle_large() {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin register_input {
  width: 100%;
  margin: 0;
  border-radius: 4px;
  border: 1px solid #dedede;
  height: 40px;
}

@mixin block-product {
  margin-bottom: 22px;
  @include media_desktop {
    height: calc(100% - 22px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    > a {
      width: 100%;
    }
  }
  @include media_mobile {
    margin-bottom: 5px;
    height: calc(100% - 16px);
  }
  .brand-new {
    width: 48px;
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
  }
  .reset-btn {
    padding: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    width: auto;
    @include media_mobile {
      top: 16px;
      right: 16px;
    }
  }
  .item-image {
    width: 100%;
  }
  .item-title {
    display: block;
    color: #000000;
    margin: 8px 0;
    font-size: 14px;
    font-weight: 400;

    @include media_desktop {
      margin: 0 0 8px 0;
    }
    @include media_mobile {
      font-size: 14px;
    }
    .number {
      color: #FF9E0D;
    }
  }
  .item-price {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Oswald', sans-serif;
    @include media_mobile {
      font-size: 12.5px;
      padding-right: 0;
    }
  }
  .item-stock {
    color: #3A9943;
    font-size: 12px;
    margin-top: 5px;
    padding-right: 50px;
  }
  .ec-cartBtn {
    padding: 13px;
    width: 50px;
    background: #DBEEDD;
    outline: 0;
    border: 0;
    img {
      vertical-align: top;
    }
  }
  .ec-productRole__actions {
    margin-top: 10px;
    .ec-select {
      margin-bottom: 0;
    }
    .ec-numberInput {
      display: none;
    }
  }
  .ec-blockBtn--action {
    height: 40px;
    &:disabled {
      font-size: 12px;
    }
  }
}


@mixin block-slick-slider {
  @include media_mobile {
    padding-bottom: 50px;
  }
  .slick-track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .slick-slide {
    height: inherit;
  }
  .ec-recommendItemRole__listItemBlock {
    margin-top: 0;
    height: 100%!important;
    position: relative;

    .ranking {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
    }

    .rank-number {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      color: #fff;
      z-index: 1;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FF9E0D;
    }
  }
  .slick-slide {
    margin: 0 8px;
  }
  .slick-list {
    margin: 0 -8px;
  }
  .slick-arrow {
    width: 36px;
    height: 36px;
    background-color: #F5F5F7;
    position: absolute;
    text-indent: -9999em;
    outline: 0;
    border: 0;
    &.slick-next {
      right: 25px;
      top: -42px;
      background-image: url("../img/common/next.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
    &.slick-prev {
      right: 65px;
      top: -42px;
      background-image: url("../img/common/previous.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
