.cart_page {
  .bg-gray {
    padding: 35px 0;
    background: #f8f8f8;
  }

  .ec-layoutRole__contents {
    max-width: unset;
  }

  .ec-secHeading {
    margin-top: 50px;
  }

  .ec-cartRole__cart {
    .py-20 {
      padding: 20px 0;
    }

    .pl-20 {
      padding-left: 20px;
    }

    .total {
      padding: 20px;
    }

    .box {
      background: $white-color;
      box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.04);
    }

    .table {
      td {
        border-top: none;
        padding: 15px 20px;
        vertical-align: middle;
        text-align: left;
      }

      thead {
        tr {
          border-bottom: 1px solid #d9d9d9;
        }

        th {
          border-top: none;
          border-bottom: none;
          padding: 15px 20px;
          color: #8a8a8a;
          font-size: 15px;
          font-weight: normal;
          white-space: nowrap;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #d9d9d9;
        }

        .ec-cartRow__name {
          a {
            color: $green-color;
          }
        }

        .ec-cartRow__sutbtotal {
          font-family: 'Oswald', sans-serif;
          font-weight: 700;
        }

        .ec-cartRow {
          .ec-cartRow__contentColumn {
            @include media_mobile {
              border: unset;
            }

            .ec-cartRow__amountUpDown {
              .ec-cartRow__amountDownButton__icon {
                img {
                  @include media_mobile {
                    width: 15px;
                  }
                }
              }

              .ec-cartRow__amountSP {
                @include media_mobile {
                  padding: 0 10px;
                }
              }

              .ec-cartRow__amountUpButton__icon {
                img {
                  @include media_mobile {
                    width: 15px;
                  }
                }
              }
            }

            .ec-cartRow__sutbtotalSP {
              @include media_mobile {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .ec-cartRole__total {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        color: #8a8a8a;
      }
    }

    .ec-cartRole__actions {
      a {
        width: 100%;
        padding: 7px 0;
        text-decoration: none;
        color: $white-color;
      }
    }
  }
}

.shopping_page {
  b, label {
    font-weight: 500;
  }
  .ec-orderDelivery__actions {
    .ec-select__delivery {
      @include media_mobile {
        margin-right: unset;
      }
      select {
        @include media_mobile {
          width: 100%;
          display: block;
        }
      }
    }
    .ec-select__delivery__provider {
      select {
        @include media_mobile {
          width: 100%;
        }
      }
    }
    .ec-select__time {
      margin-left: 30px;
      @include media_mobile {
        margin-left: unset;
      }
      select {
        @include media_mobile {
          width: 100%;
        }
      }
    }
  }
  .bg-gray {
    background: #f8f8f8;
    padding: 35px 0;
  }

  .border-btm {
    border-bottom: 1px solid #dedede;
  }

  .box {
    background: $white-color;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.04);
  }

  .ec-layoutRole__contents {
    max-width: unset;
  }

  .ec-secHeading {
    margin-top: 50px;
  }

  .ec-rectHeading {
    border-bottom: 1px solid $green-color;

    h2 {
      font-size: 18px;
      font-weight: normal;
      color: $black-color;
      padding: 0;
      background: $white-color;
    }
  }

  .ec-orderDelivery__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ec-orderDelivery__item {
    border-bottom: 1px solid #dedede;
  }

  .ec-imageGrid {
    border-top: none;
  }

  .ec-borderedList {
    li {
      border-bottom: none;
      @include media_mobile {
        margin-bottom: 20px;
      }
    }
  }

  .ec-radio {
    @include checkedRadioAnimation;
    border-bottom: 1px solid #dedede;
    padding-bottom: 28px;
  }

  .ec-orderCoupon {
    #coupon_button {
      padding: 0;
    }
  }

  .ec-orderRole__summary {
    .ec-totalBox {
      padding: 27px;
    }

    .ec-totalBox__total {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ec-totalBox__paymentTotal {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ec-totalBox__btn {
      a, button {
        width: 100%;
        height: 40px;
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ec-orderAccount {
    padding: 20px 25px 30px 25px;

    p {
      margin: 5px 0;

      &:first-of-type {
        margin-top: 15px;
      }
    }
  }

  .ec-orderDelivery {
    padding: 20px 24px 28px 24px;

    .ec-orderDelivery__edit {
      padding-bottom: 28px;
      border-bottom: 1px solid #dedede;
      @include media_mobile {
        text-align: center;
      }
    }
  }

  .ec-orderPayment {
    padding: 0 24px 30px 24px;
    .payment-option-des {
      padding-left: 28px;
      color: #666666;
    }
  }

  .ec-orderCoupon {
    padding: 0 24px 30px 24px;

    #customer_detail_box {
      border-bottom: 1px solid #dedede;
      padding-bottom: 28px;

      a {
        color: $green-color;
      }
    }
  }

  .ec-orderConfirm {
    padding: 0 24px 30px 24px;
  }
}

.cart_complete_page {
  .ec-cancel-btn, .ec-main-btn {
    padding: 8px 16px;
    text-decoration: none;

    &:hover {
      color: $white-color;
    }
  }
}

.shipping_page {
  .ec-radio {
    @include checkedRadioAnimation;
  }
}

.shipping_page, .shipping_edit_page,
.shipping_multiple_page, .shipping_multiple_edit_page,
.nonmember {
  .ec-RegisterRole__actions {
    a, button {
      height: 40px;
      display: flex;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      color: $white-color !important;
      padding: 0 20px;
      min-width: 100px;
    }
  }
}

.shopping_login_page {
  .prepend-icon {
    @include middle-element(-50%, -50%);
    left: 15px;
  }
  .ec-checkbox {
    @include checkboxAnimation;
    input[type="checkbox"] {
      margin: 0px 0 0 -19px !important;
    }
    .form-check-label {
      margin-left: 10px;
    }
  }
  .ec-login__actions {
    .ec-main-btn {
      height: 56px;
      line-height: 56px;
      width: 100%;
    }
  }
}

// Header Cart
.ec-cartNaviIsset{
  display: none;
  overflow-y: visible;
  width: 320px;
  text-align: center;
  background: #ffffff;
  box-sizing: border-box;
  padding: 20px;
  z-index: 1;
  position: absolute;
  max-height: 95vh;
  filter: drop-shadow(0 0 1px #ddd) drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  font-size: 13px;

  &::before{
    content: '';
    position: absolute;
    top:-5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    background-color: white;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    z-index: 99;
  }

  @include media_mobile {
    right: 0;
  }

  @include media_desktop {
    right: 10px;
    margin-top: 10px;
  }

  .ec-cartNaviIsset__cart-list{
    overflow-y: scroll;
    max-height: 60vh;
  }

  .ec-cartNaviIsset__cart {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #eee;

    .ec-cartNaviIsset__cartImage {
      flex: 0 0 90px;
      order: 2;
      position: relative;
    }

    .ec-cartNaviIsset__cartContent {
      flex: 0 1 auto;
      order: 1;
      padding-right: 10px;
      text-align: left;

      .ec-cartNaviIsset__cartContentTitle {
        margin-bottom: 0.5em;
        font-weight: 700;
      }

      .ec-cartNaviIsset__cartContentClass {
        margin: 0;
        margin-left: 10px;
        margin-bottom: 0.5em;
        font-size: 0.85em;
      }

      .ec-cartNaviIsset__cartContentPrice {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
      }
    }
  }
  .ec-cartNaviIsset__TotalPrice {
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 600;
  }

  #go-to-payment {
    background-color: #000;
    border-color: #000;
    &:hover {
      color: #fff;
    }
  }

  #close-cart {
    &:hover {
      color: #000;
    }
  }
}
.ec-cartNaviIsset.is-active {
  display: block;
}
