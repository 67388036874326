#page_sln_card_payment {
  .ec-layoutRole {
    background: #F3F3F3;
    .ec-layoutRole__contents {
      background: #FFFFFF;
      max-width: 1000px;
      .sln_card_payment_container {
        .ec-pageHeader {
          @include media_mobile {
            text-align: center;
          }
          h1 {
            font-size: 26px;
            font-weight: normal;
            @include media_mobile {
              font-size: 18px;
            }
          }
        }
        dl {
          font-size: 16px;
          .ec-halfInput {
            #card_CardNo {
              @include media_mobile {
                width: 100%;
              }
            }
          }
        }
        #top_box__button_menu {
          max-width: 100%;
          flex: unset;
          display: flex;
          justify-content: space-between;
          p {
            width: 45%;
            a {
              font-size: 16px;
              &.ec-blockBtn--action {
                background: #3a9943;
                border: 1px solid #3a9943;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
